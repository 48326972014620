import {VoucherState} from '@api/posMiddlewareCore/constants/voucherState';

export default {
  cs: {
    voucher: {
      detail: {
        title: 'Poukázka - {voucherNumber}',
        tabs: {
          vouchers: {
            title: 'Historie',
          },
        },
      },
      grid: {
        title: 'Poukázky',
        filters: {
          voucherNumber: 'ID',
          createdDate: 'Datum',
          stateEnum: {
            allOptions: 'Všechny stavy',
          },
          emission: {
            code: 'Kód emise',
          },
        },
        actions: {
          create: {
            title: 'Nové poukázky',
          },
          adminView: {
            title: 'Administrátor - zobrazení poukázek',
          },
        },
        advancedFilters: {
          title: 'Advanced filters',
          placeholders: {
            name: 'Name placeholder',
          },
        },
      },
      search: {
        title: 'Helpdesk - Poukázky',
      },
      form: {
        create: {
          title: '{name}',
          validate: {
            action: 'Validovat',
            success: 'Ověření nových poukázek proběhlo v pořádku. Můžete je vytvořit.',
          },
          generate: {
            action: 'Generovat',
          },
        },
        search: {
          noVoucherFind: 'Poukázku nebyla nalezena. Musíte zadat celé její unikátní číslo.',
        },
        placeholders: {
          numbers: 'Vložte kódy nových poukázek',
          voucherNumber: 'Zadejte číslo poukázky',
        },
        common: {
          sections: {
            generateVouchers: 'Založení nových poukázek',
            search: 'Vyhledat poukázku',
          },
        },
      },
      fields: {
        numbers: 'Kódy poukázek',
        voucherNumber: 'ID',
        validFrom: 'Platnost od',
        validTill: 'Platnost do',
        state: 'Stav',
        stateEnum: {
          [VoucherState.readyForActivation]: 'K aktivaci',
          [VoucherState.readyForUse]: 'K použití',
          [VoucherState.reserved]: 'Rezervováno',
          [VoucherState.used]: 'Uplatněná',
          [VoucherState.expired]: 'Vypršená',
          [VoucherState.canceled]: 'Zrušená',
        },
        usedAt: 'Uplatněno',
        usedPlace: 'Místo uplatnění',
        issuer: 'Vydavatel',
        emission: {
          code: 'Kód emise',
          name: 'Název emise',
        },
        customerNumber: 'Věrnost ID',
        amount: 'Použitelná hodnota',
        promotionCode: 'Promoce',
        articleNumber: 'ID Artiklu',
        maxUsageCount: 'Max. počet použití',
        usageCount: 'Počet použití',
      },
      validations: {
        numbers: 'Vouchery s čísly {voucherNumbers} nejsou validní',
      },
    },
  },
  sk: {
    voucher: {
      detail: {
        title: 'Voucher - {voucherNumber}',
        tabs: {
          vouchers: {
            title: 'História',
          },
        },
      },
      grid: {
        title: 'Vouchery',
        filters: {
          voucherNumber: 'ID',
          createdDate: 'Dátum',
          stateEnum: {
            allOptions: 'Všetky stavy',
          },
          emission: {
            code: 'Kód emisie',
          },
        },
        actions: {
          create: {
            title: 'Nové vouchery',
          },
          adminView: {
            title: 'Administrátor - zobrazenie voucherov',
          },
        },
        advancedFilters: {
          title: 'Pokročilé filtre',
          placeholders: {
            name: 'Umiestnenie pre názov',
          },
        },
      },
      search: {
        title: 'Helpdesk - Vouchery',
      },
      form: {
        create: {
          title: '{name}',
          validate: {
            action: 'Overiť',
            success: 'Overenie nových voucherov prebehlo v poriadku. Môžete ich vytvoriť.',
          },
          generate: {
            action: 'Generovať',
          },
        },
        search: {
          noVoucherFind: 'Voucher nebol nájdený. Musíte zadať jeho unikátne číslo.',
        },
        placeholders: {
          numbers: 'Vložte kódy nových voucherov',
          voucherNumber: 'Zadajte číslo voucheru',
        },
        common: {
          sections: {
            generateVouchers: 'Vytvorenie nových voucherov',
            search: 'Vyhľadať voucher',
          },
        },
      },
      fields: {
        numbers: 'Kódy voucherov',
        voucherNumber: 'ID',
        validFrom: 'Platnosť od',
        validTill: 'Platnosť do',
        state: 'Stav',
        stateEnum: {
          [VoucherState.readyForActivation]: 'Pripravené na aktiváciu',
          [VoucherState.readyForUse]: 'Pripravené na použitie',
          [VoucherState.reserved]: 'Rezervované',
          [VoucherState.used]: 'Použité',
          [VoucherState.expired]: 'Expirované',
          [VoucherState.canceled]: 'Zrušené',
        },
        usedAt: 'Použité',
        usedPlace: 'Miesto použitia',
        issuer: 'Vydavateľ',
        emission: {
          code: 'Kód emisie',
          name: 'Názov emisie',
        },
        customerNumber: 'ID vernostného programu',
        amount: 'Použiteľná hodnota',
        promotionCode: 'Promo kód',
        articleNumber: 'ID Artiklu',
        maxUsageCount: 'Max. počet použití',
        usageCount: 'Počet použití',
      },
      validations: {
        numbers: 'Vouchery s číslami {voucherNumbers} nie sú platné',
      },
    },
  },
  en: {
    voucher: {
      detail: {
        title: 'Voucher - {voucherNumber}',
        tabs: {
          vouchers: {
            title: 'History',
          },
        },
      },
      grid: {
        title: 'Vouchers',
        filters: {
          voucherNumber: 'ID',
          createdDate: 'Date',
          stateEnum: {
            allOptions: 'All states',
          },
          emission: {
            code: 'Emission code',
          },
        },
        actions: {
          create: {
            title: 'New vouchers',
          },
          adminView: {
            title: 'Admin - view vouchers',
          },
        },
        advancedFilters: {
          title: 'Advanced filters',
          placeholders: {
            name: 'Name placeholder',
          },
        },
      },
      search: {
        title: 'Helpdesk - Vouchers',
      },
      form: {
        create: {
          title: '{name}',
          validate: {
            action: 'Validate',
            success: 'Validation of new vouchers was successful. You can create them.',
          },
          generate: {
            action: 'Generate',
          },
        },
        search: {
          noVoucherFind: 'Voucher was not found. You have to enter the whole unique number.',
        },
        placeholders: {
          numbers: 'Insert codes of new vouchers',
          voucherNumber: 'Enter voucher number',
        },
        common: {
          sections: {
            generateVouchers: 'Generate',
            search: 'Search',
          },
        },
      },
      fields: {
        numbers: 'Vouchers codes',
        voucherNumber: 'ID',
        validFrom: 'Valid from',
        validTill: 'Valid till',
        state: 'State',
        stateEnum: {
          [VoucherState.readyForActivation]: 'Ready for activation',
          [VoucherState.readyForUse]: 'Ready for use',
          [VoucherState.reserved]: 'Reserved',
          [VoucherState.used]: 'Used',
          [VoucherState.expired]: 'Expired',
          [VoucherState.canceled]: 'Canceled',
        },
        usedAt: 'Used at',
        usedPlace: 'Used place',
        issuer: 'Issuer',
        emission: {
          code: 'Emission code',
          name: 'Emission name',
        },
        customerNumber: 'Customer ID',
        amount: 'Amount',
        promotionCode: 'Promotion',
        articleNumber: 'Article ID',
        maxUsageCount: 'Max. usage count',
        usageCount: 'Usage count',
      },
      validations: {
        numbers: 'Vouchers with numbers {voucherNumbers} are not valid',
      },
    },
  },
  de: {
    voucher: {
      detail: {
        title: 'Gutschein - {voucherNumber}',
        tabs: {
          vouchers: {
            title: 'Historie',
          },
        },
      },
      grid: {
        title: 'Gutscheine',
        filters: {
          voucherNumber: 'ID',
          createdDate: 'Datum',
          stateEnum: {
            allOptions: 'Alle Zustände',
          },
          emission: {
            code: 'Emissionscode',
          },
        },
        actions: {
          create: {
            title: 'Neue Gutscheine',
          },
          adminView: {
            title: 'Administrator - Ansicht von Gutscheinen',
          },
        },
        advancedFilters: {
          title: 'Erweiterte Filter',
          placeholders: {
            name: 'Platzhalter für Namen',
          },
        },
      },
      search: {
        title: 'Helpdesk - Gutscheine',
      },
      form: {
        create: {
          title: '{name}',
          validate: {
            action: 'Validieren',
            success: 'Die Überprüfung neuer Gutscheine war erfolgreich. Sie können sie erstellen.',
          },
          generate: {
            action: 'Generieren',
          },
        },
        search: {
          noVoucherFind: 'Gutschein wurde nicht gefunden. Sie müssen die vollständige eindeutige Nummer eingeben.',
        },
        placeholders: {
          numbers: 'Geben Sie die Codes der neuen Gutscheine ein',
          voucherNumber: 'Geben Sie die Gutscheinnummer ein',
        },
        common: {
          sections: {
            generateVouchers: 'Neue Gutscheine erstellen',
            search: 'Gutschein suchen',
          },
        },
      },
      fields: {
        numbers: 'Gutschein-Codes',
        voucherNumber: 'ID',
        validFrom: 'Gültig ab',
        validTill: 'Gültig bis',
        state: 'Status',
        stateEnum: {
          [VoucherState.readyForActivation]: 'Bereit zur Aktivierung',
          [VoucherState.readyForUse]: 'Bereit zur Verwendung',
          [VoucherState.reserved]: 'Reserviert',
          [VoucherState.used]: 'Verwendet',
          [VoucherState.expired]: 'Abgelaufen',
          [VoucherState.canceled]: 'Storniert',
        },
        usedAt: 'Verwendet am',
        usedPlace: 'Verwendungsort',
        issuer: 'Herausgeber',
        emission: {
          code: 'Emissionscode',
          name: 'Name der Emission',
        },
        customerNumber: 'Kundennummer',
        amount: 'Verwendbarer Betrag',
        promotionCode: 'Promotionscode',
        articleNumber: 'Artikelnummer',
        maxUsageCount: 'Maximale Verwendungszahl',
        usageCount: 'Verwendungszahl',
      },
      validations: {
        numbers: 'Gutscheine mit den Nummern {voucherNumbers} sind ungültig',
      },
    },
  },
};

import {VoucherEmissionType} from '@api/posMiddlewareCore/constants/voucherEmissionType';

export default {
  cs: {
    voucherEmission: {
      detail: {
        title: 'Emise - {name}',
        actions: {
          generateVouchers: {
            title: 'Založit poukázky',
          },
        },
        tabs: {
          vouchers: {
            title: 'Přehled poukázek',
          },
        },
      },
      grid: {
        title: 'Emise poukázek',
        filters: {
          name: 'Název',
          code: 'Kód',
          createdDate: 'Vytvořeno',
          emissionGroupId: 'Skupina',
          typeEnum: {
            allOptions: 'Všechny typy',
          },
        },
        actions: {
          create: {
            title: 'Nová emise',
          },
        },
        advancedFilters: {
          title: 'Filtrovat emise',
          placeholders: {
            name: 'Název',
            code: 'Kód',
            emissionGroupId: 'Všechny skupiny',
          },
        },
      },
      form: {
        create: {
          title: 'Vytvoření nové emise',
        },
        update: {
          title: 'Editace emise',
        },
        placeholders: {
          name: 'Zadejte název',
          code: 'Zadejte kód',
          type: 'Vyberte typ poukázky',
          amount: 'Zadejte hodnotu',
          issuer: 'Zadejte vydavatele',
          emissionGroupId: 'Vybertu skupinu emise',
          validFrom: 'Zadejte platnost od',
          validToUseTill: 'Zadejte platnost do',
          technicalArticleNumber: 'Zadejte ID artiklu',
          generateFromNumber: 'Zadejte rozsah od',
          generateQuantity: 'Zadejte počet ks',
          promotionCode: 'Zadejte název promoce',
          maxUsageCount: 'Zadejte max. počet použití',
        },
        common: {
          sections: {
            detail: 'Detail emise',
            configuration: 'Konfigurace emise',
          },
        },
      },
      fields: {
        name: 'Název',
        code: 'Kód',
        type: 'Typ poukázky',
        typeEnum: {
          [VoucherEmissionType.discountAbsolute]: 'Sleva absolutní',
          [VoucherEmissionType.discountPercentage]: 'Sleva procentuální',
        },
        amount: 'Hodnota',
        issuer: 'Vydavatel',
        emissionGroupId: 'Skupina emise',
        validFrom: 'Platnost od',
        validToUseTill: 'Platnost do',
        technicalArticleNumber: 'Číslo technického artiklu',
        generateFromNumber: 'Rozsah od',
        generateQuantity: 'Počet ks',
        configuration: 'Maska',
        emissionGroup: 'Skupina',
        editedDate: 'Datum editace',
        createdDate: 'Datum vytvoření',
        promotionCode: 'Promoce',
        example: 'Příklad',
        edited: 'Upraveno',
        created: 'Vytvořeno',
        maxUsageCount: 'Max. počet použití jednotlivých poukázek',
        maxUsageCountEnum: {
          withoutRestrictions: 'Bez omezení',
        },
      },
    },
  },
  sk: {
    voucherEmission: {
      detail: {
        title: 'Emisia - {name}',
        actions: {
          generateVouchers: {
            title: 'Vytvoriť poukážky',
          },
        },
        tabs: {
          vouchers: {
            title: 'Prehľad poukážok',
          },
        },
      },
      grid: {
        title: 'Emisie poukážok',
        filters: {
          name: 'Názov',
          code: 'Kód',
          createdDate: 'Vytvorené',
          emissionGroupId: 'Skupina',
          typeEnum: {
            allOptions: 'Všetky typy',
          },
        },
        actions: {
          create: {
            title: 'Nová emisia',
          },
        },
        advancedFilters: {
          title: 'Filtrovať emisie',
          placeholders: {
            name: 'Názov',
            code: 'Kód',
            emissionGroupId: 'Všetky skupiny',
          },
        },
      },
      form: {
        create: {
          title: 'Vytvorenie novej emisie',
        },
        update: {
          title: 'Editácia emisie',
        },
        placeholders: {
          name: 'Zadajte názov',
          code: 'Zadajte kód',
          type: 'Vyberte typ poukážky',
          amount: 'Zadajte hodnotu',
          issuer: 'Zadajte vydavateľa',
          emissionGroupId: 'Vyberte skupinu emisie',
          validFrom: 'Zadajte platnosť od',
          validToUseTill: 'Zadajte platnosť do',
          technicalArticleNumber: 'Zadajte ID artiklu',
          generateFromNumber: 'Zadajte rozsah od',
          generateQuantity: 'Zadajte počet ks',
          promotionCode: 'Zadajte názov promócie',
          maxUsageCount: 'Zadajte max. počet použití',
        },
        common: {
          sections: {
            detail: 'Detail emisie',
            configuration: 'Konfigurácia emisie',
          },
        },
      },
      fields: {
        name: 'Názov',
        code: 'Kód',
        type: 'Typ poukážky',
        typeEnum: {
          [VoucherEmissionType.discountAbsolute]: 'Zľava absolutná',
          [VoucherEmissionType.discountPercentage]: 'Zľava percentuálna',
        },
        amount: 'Hodnota',
        issuer: 'Vydavateľ',
        emissionGroupId: 'Skupina emisie',
        validFrom: 'Platnosť od',
        validToUseTill: 'Platnosť do',
        technicalArticleNumber: 'Číslo technického artiklu',
        generateFromNumber: 'Rozsah od',
        generateQuantity: 'Počet ks',
        configuration: 'Maska',
        emissionGroup: 'Skupina',
        editedDate: 'Dátum editácie',
        createdDate: 'Dátum vytvorenia',
        promotionCode: 'Promócia',
        example: 'Príklad',
        edited: 'Upravené',
        created: 'Vytvorené',
        maxUsageCount: 'Max. počet použití jednotlivých poukážok',
        maxUsageCountEnum: {
          withoutRestrictions: 'Bez obmedzení',
        },
      },
    },
  },
  en: {
    voucherEmission: {
      detail: {
        title: 'Emission - {name}',
        actions: {
          generateVouchers: {
            title: 'Generate vouchers',
          },
        },
        tabs: {
          vouchers: {
            title: 'Vouchers',
          },
        },
      },
      grid: {
        title: 'Voucher emissions',
        filters: {
          name: 'Name',
          code: 'Code',
          createdDate: 'Created',
          emissionGroupId: 'Group',
          typeEnum: {
            allOptions: 'All types',
          },
        },
        actions: {
          create: {
            title: 'New emission',
          },
        },
        advancedFilters: {
          title: 'Filter emissions',
          placeholders: {
            name: 'Name',
            code: 'Code',
            emissionGroupId: 'All groups',
          },
        },
      },
      form: {
        create: {
          title: 'Create new emission',
        },
        update: {
          title: 'Edit emission',
        },
        placeholders: {
          name: 'Enter name',
          code: 'Enter code',
          type: 'Select voucher type',
          amount: 'Enter value',
          issuer: 'Enter issuer',
          emissionGroupId: 'Select emission group',
          validFrom: 'Enter valid from',
          validToUseTill: 'Enter valid to',
          technicalArticleNumber: 'Enter technical article number',
          generateFromNumber: 'Enter range from',
          generateQuantity: 'Enter quantity',
          promotionCode: 'Enter promotion name',
          maxUsageCount: 'Enter max. usage count',
        },
        common: {
          sections: {
            detail: 'Emission detail',
            configuration: 'Emission configuration',
          },
        },
      },
      fields: {
        name: 'Name',
        code: 'Code',
        type: 'Voucher type',
        typeEnum: {
          [VoucherEmissionType.discountAbsolute]: 'Discount absolute',
          [VoucherEmissionType.discountPercentage]: 'Discount percentage',
        },
        amount: 'Value',
        issuer: 'Issuer',
        emissionGroupId: 'Emission group',
        validFrom: 'Valid from',
        validToUseTill: 'Valid to',
        technicalArticleNumber: 'Technical article number',
        generateFromNumber: 'Range from',
        generateQuantity: 'Quantity',
        configuration: 'Mask',
        emissionGroup: 'Group',
        editedDate: 'Edited date',
        createdDate: 'Created date',
        promotionCode: 'Promotion',
        example: 'Example',
        edited: 'Edited',
        created: 'Created',
        maxUsageCount: 'Max. usage count of vouchers',
        maxUsageCountEnum: {
          withoutRestrictions: 'Without restrictions',
        },
      },
    },
  },
  de: {
    voucherEmission: {
      detail: {
        title: 'Emission - {name}',
        actions: {
          generateVouchers: {
            title: 'Gutscheine erstellen',
          },
        },
        tabs: {
          vouchers: {
            title: 'Übersicht der Gutscheine',
          },
        },
      },
      grid: {
        title: 'Emissionen von Gutscheinen',
        filters: {
          name: 'Name',
          code: 'Code',
          createdDate: 'Erstellt am',
          emissionGroupId: 'Gruppe',
          typeEnum: {
            allOptions: 'Alle Typen',
          },
        },
        actions: {
          create: {
            title: 'Neue Emission',
          },
        },
        advancedFilters: {
          title: 'Emissionen filtern',
          placeholders: {
            name: 'Name',
            code: 'Code',
            emissionGroupId: 'Alle Gruppen',
          },
        },
      },
      form: {
        create: {
          title: 'Neue Emission erstellen',
        },
        update: {
          title: 'Emission bearbeiten',
        },
        placeholders: {
          name: 'Name eingeben',
          code: 'Code eingeben',
          type: 'Gutscheintyp auswählen',
          amount: 'Wert eingeben',
          issuer: 'Herausgeber eingeben',
          emissionGroupId: 'Gruppe auswählen',
          validFrom: 'Gültig ab',
          validToUseTill: 'Gültig bis',
          technicalArticleNumber: 'Technische Artikelnummer eingeben',
          generateFromNumber: 'Bereich ab',
          generateQuantity: 'Anzahl eingeben',
          promotionCode: 'Promotionsname eingeben',
          maxUsageCount: 'Maximale Anzahl der Einlösungen eingeben',
        },
        common: {
          sections: {
            detail: 'Details zur Emission',
            configuration: 'Einstellungen zur Emission',
          },
        },
      },
      fields: {
        name: 'Name',
        code: 'Code',
        type: 'Gutscheintyp',
        typeEnum: {
          [VoucherEmissionType.discountAbsolute]: 'Absoluter Rabatt',
          [VoucherEmissionType.discountPercentage]: 'Prozentualer Rabatt',
        },
        amount: 'Wert',
        issuer: 'Herausgeber',
        emissionGroupId: 'Gruppe der Emission',
        validFrom: 'Gültig ab',
        validToUseTill: 'Gültig bis',
        technicalArticleNumber: 'Technische Artikelnummer',
        generateFromNumber: 'Bereich ab',
        generateQuantity: 'Anzahl',
        configuration: 'Maske',
        emissionGroup: 'Emissionsgruppe',
        editedDate: 'Bearbeitungsdatum',
        createdDate: 'Erstellungsdatum',
        promotionCode: 'Promotionsname',
        example: 'Beispiel',
        edited: 'Bearbeitet',
        created: 'Erstellt',
        maxUsageCount: 'Maximale Anzahl der Einlösungen für einzelne Gutscheine',
        maxUsageCountEnum: {
          withoutRestrictions: 'Unbegrenzt',
        },
      },
    },
  },
};
